<template>
  <div>
    <div v-if="ingredient == null">
      <div class="spinner-grow spinner-grow-sm" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div v-if="ingredient != null">
      <div
        :id="'macrosIngredients' + idingredient"
        :data-proteine="ingredient.proteine"
        :data-glucide="ingredient.glucide"
        :data-lipide="ingredient.lipide"
      >
        {{ ingredient.description.it.nom }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Ingredient",
  props: ["ingredientref", "updateMacrosCalcul"],
  components: {},
  data() {
    return {
      ingredient: null,
      idingredient: null,
    };
  },
  mounted() {
    console.log("reference: " + JSON.stringify(this.ingredientref));
    this.showIngredientFromRef().then(() => {
      this.updateMacrosCalcul();
    });
  },
  methods: {
    async showIngredientFromRef() {
      var getRef = await this.ingredientref.get();
      this.ingredient = getRef.data();
      this.idingredient = getRef.id;
    },
  },
};
</script>